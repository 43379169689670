import { useContext, useEffect, useState } from "react"
import { END_POINT } from "../Constants"
import useNetwork from "../hooks/useNetwork"
import { CommonContext } from "../contexts/CommonContext"
import Loader from "../components/Loader"
import { AuthContext } from "../contexts/AuthContext"
import TykoonFullLogo from '../assets/brand-origin.png'
import { useNavigate } from "react-router-dom"
import InvoiceIcon from '../assets/invoice.png'
import { Button, Box, Card, Paper, Stack, Divider } from '@mui/material'
import ViewsIcon from '../assets/views.png'
import RatingIcon from '../assets/rating.png'
import ApptIcon from '../assets/appointment.png'
import EnquiryIcon from '../assets/enquiry.png'
import SendIcon from '@mui/icons-material/Send'
import ShareLink from '../components/ShareLink'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Capacitor } from "@capacitor/core"
import AppBlocker from "../components/AppBlocker"


const styles = {
  contentCard : {
    height:80,
    minWidth:'100px',
    display: 'flex',
    width:'35%',
    flexDirection:'column',
    justifyContent:'center',
    padding:'10px 20px 0 20px',
    borderRadius:'5px',
    boxShadow: '5px 5px 15px 5px #eaeaea'
  },
  contentCardDesk : {
    height:80,
    minWidth:'100px',
    display: 'flex',
    flexDirection:'column',
    justifyContent:'center',
    padding:'10px 20px 0 20px',
    borderRadius:'5px',
    boxShadow: '5px 5px 15px 5px #eaeaea'
  },
  actIcon : {
    width:'30px'
  }
}

function ElectionAnalysis() {

  const navigate     = useNavigate()
  const { fetchApi } = useNetwork()
  const { showPopup, isDesktop,
    setBlocker }            = useContext(CommonContext)
   const { userData, setUserData } = useContext(AuthContext)

  const [loading, setLoading]           = useState(false)
  const [enquiries, setEnquiries]       = useState([])

  useEffect(() => {

  }, [])



  return (
    <>
    {
      loading ? 
      <Loader /> :
      <Box sx={{padding:'4vw'}}>
                <img src="https://play-lh.googleusercontent.com/M0bNOzeF-xs-0_CvsPnCitsI4iGODTo4CYAfz93rPOknGIdm2g9-FI4X8HXP8sABFlY" style={{width:'200px'}} />  

        <h2>Election Analysis</h2>
        
        <Stack>
          <Stack spacing={{ sm: 1, xs:1, lg:4,  }} mt={5}  direction="row" 
            justifyContent="space-around" useFlexGap flexWrap="wrap">
            
            <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
              <Stack direction="row">
                <span style={styles.cardIcon}>
                  <img src="https://content.tupaki.com/en/feeds/2024/02/09/281030-ysrcp2.gif" style={styles.actIcon}/>
                </span>
                <Box sx={{fontSize:'35px', ml:2, color:'orange'}}>52.05%</Box>
              </Stack>
              
              <Button>
                YSRCP
              </Button>
            </Paper>

          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={'https://upload.wikimedia.org/wikipedia/commons/c/ca/Telugu_Desam_Party_Flag.png'} style={styles.actIcon}/>
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>47.95%</Box>  
            </Stack>
            <Button>
              TDP
            </Button>
          </Paper>

     

        </Stack>

        <Box sx={{mt:2}}></Box>
        <h2>Mandal Wise Analysis</h2>
        <h2>Chandragiri</h2>

        <Stack spacing={{ sm: 1, xs:1, lg:4,  }} mt={3}  direction="row" 
            justifyContent="space-around" useFlexGap flexWrap="wrap">
            
            <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
              <Stack direction="row">
                <span style={styles.cardIcon}>
                  <img src="https://content.tupaki.com/en/feeds/2024/02/09/281030-ysrcp2.gif" style={styles.actIcon}/>
                </span>
                <Box sx={{fontSize:'35px', ml:2, color:'orange'}}>51.5%</Box>
              </Stack>
              
              <Button>
                YSRCP
              </Button>
            </Paper>

          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={'https://upload.wikimedia.org/wikipedia/commons/c/ca/Telugu_Desam_Party_Flag.png'} style={styles.actIcon}/>
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>48.5%</Box>  
            </Stack>
            <Button>
              TDP
            </Button>
          </Paper>

          {/* <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={EnquiryIcon} style={styles.actIcon}/>               
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>3.7%</Box>  
            </Stack>
            <Button>
              Others
            </Button>
          </Paper> */}
        </Stack>


        <Box sx={{mt:2}}></Box>
        <h2>CHINNAGOTTIGALLU</h2>

        <Stack spacing={{ sm: 1, xs:1, lg:4,  }} mt={3}  direction="row" 
            justifyContent="space-around" useFlexGap flexWrap="wrap">
            
            <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
              <Stack direction="row">
                <span style={styles.cardIcon}>
                  <img src="https://content.tupaki.com/en/feeds/2024/02/09/281030-ysrcp2.gif" style={styles.actIcon}/>
                </span>
                <Box sx={{fontSize:'35px', ml:2, color:'orange'}}>50.8%</Box>
              </Stack>
              
              <Button>
                YSRCP
              </Button>
            </Paper>

          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={'https://upload.wikimedia.org/wikipedia/commons/c/ca/Telugu_Desam_Party_Flag.png'} style={styles.actIcon}/>
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>49.2%</Box>  
            </Stack>
            <Button>
              TDP
            </Button>
          </Paper>

          {/* <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={EnquiryIcon} style={styles.actIcon}/>               
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>5.7%</Box>  
            </Stack>
            <Button>
              Others
            </Button>
          </Paper> */}
        </Stack>


        <Box sx={{mt:2}}></Box>
        <h2>PAKALA</h2>

        <Stack spacing={{ sm: 1, xs:1, lg:4,  }} mt={3}  direction="row" 
            justifyContent="space-around" useFlexGap flexWrap="wrap">
            
            <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
              <Stack direction="row">
                <span style={styles.cardIcon}>
                  <img src="https://content.tupaki.com/en/feeds/2024/02/09/281030-ysrcp2.gif" style={styles.actIcon}/>
                </span>
                <Box sx={{fontSize:'35px', ml:2, color:'orange'}}>42.7%</Box>
              </Stack>
              
              <Button>
                YSRCP
              </Button>
            </Paper>

          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={'https://upload.wikimedia.org/wikipedia/commons/c/ca/Telugu_Desam_Party_Flag.png'} style={styles.actIcon}/>
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>57.3%</Box>  
            </Stack>
            <Button>
              TDP
            </Button>
          </Paper>

          {/* <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={EnquiryIcon} style={styles.actIcon}/>               
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>2.2%</Box>  
            </Stack>
            <Button>
              Others
            </Button>
          </Paper> */}
        </Stack>


        <Box sx={{mt:2}}></Box>
        <h2>RAMACHANDRAPURAM</h2>

        <Stack spacing={{ sm: 1, xs:1, lg:4,  }} mt={3}  direction="row" 
            justifyContent="space-around" useFlexGap flexWrap="wrap">
            
            <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
              <Stack direction="row">
                <span style={styles.cardIcon}>
                  <img src="https://content.tupaki.com/en/feeds/2024/02/09/281030-ysrcp2.gif" style={styles.actIcon}/>
                </span>
                <Box sx={{fontSize:'35px', ml:2, color:'orange'}}>55.6%</Box>
              </Stack>
              
              <Button>
                YSRCP
              </Button>
            </Paper>

          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={'https://upload.wikimedia.org/wikipedia/commons/c/ca/Telugu_Desam_Party_Flag.png'} style={styles.actIcon}/>
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>44.4%</Box>  
            </Stack>
            <Button>
              TDP
            </Button>
          </Paper>

          {/* <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={EnquiryIcon} style={styles.actIcon}/>               
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>0.8%</Box>  
            </Stack>
            <Button>
              Others
            </Button>
          </Paper> */}
        </Stack>


        <Box sx={{mt:2}}></Box>
        <h2>TIRUPATI(RURAL)</h2>

        <Stack spacing={{ sm: 1, xs:1, lg:4,  }} mt={3}  direction="row" 
            justifyContent="space-around" useFlexGap flexWrap="wrap">
            
            <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
              <Stack direction="row">
                <span style={styles.cardIcon}>
                  <img src="https://content.tupaki.com/en/feeds/2024/02/09/281030-ysrcp2.gif" style={styles.actIcon}/>
                </span>
                <Box sx={{fontSize:'35px', ml:2, color:'orange'}}>51.9%</Box>
              </Stack>
              
              <Button>
                YSRCP
              </Button>
            </Paper>

          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={'https://upload.wikimedia.org/wikipedia/commons/c/ca/Telugu_Desam_Party_Flag.png'} style={styles.actIcon}/>
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>48.1%</Box>  
            </Stack>
            <Button>
              TDP
            </Button>
          </Paper>
{/* 
          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={EnquiryIcon} style={styles.actIcon}/>               
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>3.6%</Box>  
            </Stack>
            <Button>
              Others
            </Button>
          </Paper> */}
        </Stack>


        <Box sx={{mt:2}}></Box>
        <h2>YERRAVARIPALEM</h2>

        <Stack spacing={{ sm: 1, xs:1, lg:4,  }} mt={3}  direction="row" 
            justifyContent="space-around" useFlexGap flexWrap="wrap">
            
            <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
              <Stack direction="row">
                <span style={styles.cardIcon}>
                  <img src="https://content.tupaki.com/en/feeds/2024/02/09/281030-ysrcp2.gif" style={styles.actIcon}/>
                </span>
                <Box sx={{fontSize:'35px', ml:2, color:'orange'}}>59.8%</Box>
              </Stack>
              
              <Button>
                YSRCP
              </Button>
            </Paper>

          <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={'https://upload.wikimedia.org/wikipedia/commons/c/ca/Telugu_Desam_Party_Flag.png'} style={styles.actIcon}/>
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>40.2%</Box>  
            </Stack>
            <Button>
              TDP
            </Button>
          </Paper>

          {/* <Paper style={isDesktop ? styles.contentCardDesk : styles.contentCard}>
            <Stack direction="row">
              <span style={styles.cardIcon}>
                <img src={EnquiryIcon} style={styles.actIcon}/>               
              </span>
              <Box sx={{fontSize:'35px', ml:2}}>2.4%</Box>  
            </Stack>
            <Button>
              Others
            </Button>
          </Paper> */}
        </Stack>

        </Stack>
      </Box>
    }
    </>
  )
}

export default ElectionAnalysis
