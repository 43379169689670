import React from "react"
import CardBase from "./CardBase"
import ImageLoader from '../components/ImageLoader'
import { Button, Box, Paper, Grid, Fab, Badge, Card , useMediaQuery} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'
import LanguageIcon from '@mui/icons-material/Language'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Email'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { FaGlobe, FaShareAlt } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { FaUserTie } from "react-icons/fa";
import { LiaIndustrySolid } from "react-icons/lia";
import StoreIcon from '@mui/icons-material/Store';
import CollectionsIcon from '@mui/icons-material/Collections';
import PaymentsIcon from '@mui/icons-material/Payments';
import { FaHandshake } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineBookmarkAdded } from "react-icons/md";
import { LuSendHorizonal } from "react-icons/lu";


let styles = {
  logo : {
    width:'2vw',
    height:'2vw',
    marginTop:'15px',
    backgroundColor : 'white',
    borderRadius : '5px',
    padding : '2px 10px'
  },
  header : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'center',
    alignItems : 'center',
    gap : '50px',
    marginTop : '-40px'
  },
  defIcon: {
    fontSize:'5vw'
  },
  card : {
    backgroundColor : '#53AF56',
    color : 'white',
    borderRadius : '7px'
  },
  profile : {
    borderRadius : '50%',
    width : '8vw',
    border : '2px solid white',
    backgroundColor : 'white',
  },
  logos : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'space-between'
  },
  shareIcon : {
    background : 'white',
    color : 'green',
    padding : '7px',
    fontSize : '18px',
    borderRadius : '50%',
    float : 'right',
    marginTop : '-40px'
  },
  profilePicCont : {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:'25vw'
  },
  userCard : {
    display:'flex',
    flexDirection:'column',
    padding:'3vw',
    background:'linear-gradient(145deg, #1F277B 20%, white 20%,lightgray 100%)',
    color : 'white',
    boxShadow : '0 0 20px rgba(173, 216, 230, 0.5)',
  },
 socialMediaCard : {
  backgroundColor : '#D9D9D9',
  marginBlock : '20px',
  border : '2px solid white',
 },
 circleWhatsup : {
  backgroundColor : '#65ac4c',
  border : '2px solid white',
  borderRadius  : '50%',
  fontSize : '30px',
  color : 'white',
  padding : '10px',
  cursor : 'pointer',
  boxShadow : '0.4rem 0.1rem 1rem #65ac4c81'
 },
 circleEmail : {
  backgroundColor : '#fd9001  ',
  border : '2px solid white',
  borderRadius  : '50%',
  fontSize : '30px',
  color : 'white',
  padding : '10px',
  cursor : 'pointer',
  boxShadow : '0.4rem 0.1rem 1rem #fd900181'
 },
 circleWeb : {
  backgroundColor : '#2895f6',
  border : '2px solid white',
  borderRadius  : '50%',
  fontSize : '30px',
  color : 'white',
  padding : '10px',
  cursor : 'pointer',
  boxShadow : '0.4rem 0.1rem 1rem #2895f681'
 },
  userNameCont: {
    textAlign:'justify',
    marginTop:'2px',
    fontSize:'30px'
  },
  aboutMeCont: {
    marginTop:'10px',
    textAlign:'justify',
    fontSize:'16px'
  },
  professionCont: {
    marginTop : '-10px',
    fontSize : '14px',
    display : 'flex',
    gap : '5px'
  },
  contactCont: {
    display:'flex',
    justifyContent:'space-around'
  },
  socialMediaCont: {
    position : 'fixed',
    bottom : '120px',
    display:'flex',
    marginTop:'30px',
    justifyContent:'center',
    gap : '7px',
    zIndex : '20',
    marginLeft : '10vw',
    
  },
  enquiryCont: {
    display:'flex',
    marginBlock:'10px',
    justifyContent:'space-around',
    alignItems : 'center',
    gap : '5px'
  },
  circleMobile : {
    border:'2px solid white',
    borderRadius:'50%',
    padding:'10px',
    cursor : 'pointer',
    backgroundColor : '#1F277C',
    color : 'white',
    fontSize : '30px',
    boxShadow : '0.4rem 0.1rem 1rem #014e8d81'
  },
  squareIcon: {
    padding:'10px',
    borderRadius:'50%',
    boxShadow:' 0px 0px 11px 2px rgb(229 229 229)',
    cursor : 'pointer',
    backgroundColor : 'white',
    color : 'gray',
    fontSize : '20px',
   marginTop : '-10px'
  },
  galleryCont: {
    display:'flex'
  },
  galleryImg : {
    height:'50vw',
    width:'35vw',
    maxWidth:'400px',
    borderRadius:'5px'
  },

  companiesCont: {
    display:'flex',
    flexDirection:'column',
    gap : '5px'
  },
  companyCont: {
    border:'1px solid black',
    padding:'10px',
    borderRadius:'10px',
    color : 'black'
  },
  companyNameCont : {
    fontSize:'20px',
    marginBottom:'10px',
    borderBottom:'3px solid #eaeaea'
  },
  companyDescCont : {
    fontSize:'17px'
  },
  companyImg: {
    width:'80vw',
    maxWidth:'400px',
    borderRadius:'5px'
  },

  ecommCont : {
    border:'1px solid black',
    padding:'10px',
    margin:'10px',
    textAlign:'center',
    display:'grid',
    borderRadius:'10px',
    color : 'black'
  },
  ecommProdImg: {
    width:'22vw',
    borderRadius:'5px',
    boxShadow : '0 0 20px 5px rgba(0, 0, 0, 0.5)'
  },

  productsCont: {
    display:'flex',
    flexDirection:'column'
  },
  productCont: {
    border:'1px solid #919191',
    padding:'10px',
    borderRadius:'10px'
  },
  productNameCont: {
    fontSize:'20px',
    marginBottom:'10px',
    borderBottom:'1px solid #eaeaea'
  },
  productDescCont: {
    fontSize:'17px',
    marginBottom:'10px'
  },
  prodImg: {
    width:'80vw',
    borderRadius:'5px',
    marginTop:'10px',
    maxWidth:'400px'
  },
  
  paymentLabel: {
    fontSize:'18px',
    marginTop:'10px',
    marginBottom : '10px',
    fontWeight:'780',
  },

  
  logoImg : {
    width:'80vw',
    marginBottom:'20px'
  },
  navIcons : {
    fontSize : '2vw',
    color : 'gray'
  },
  navCards : {
    backgroundColor : 'lightgray',
    width : '5vw',
    height : 'fit-content',
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
    border : '3px solid white',
    borderRadius : '8px'
  },
  navH3 : {
    fontSize : '14px',
    textWrap : 'nowrap'
  },
  h3Tag  : {
    color : 'black',
    fontSize : '22px'
  },
  paymentDetails : {
    color : 'black'
  },
  a : {
    textDecoration : 'none',
    transition : '5s all ease-in-out',
    color : 'black'
  },
  pagination : {
    position : 'fixed',
    bottom : 0,
    display : 'flex',
    justifyContent : 'space-evenly',
    gap : '2px',
    width : '480px'
  },
  eachBoxPagination : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
    cursor : 'pointer'
  },
  eachPagiIcon : {
    color : '#1F277B',
    fontSize : '28px'
  },
  webPagination : {
    
    fontSize : '28px',
    backgroundColor : 'lightgray' ,
    color : 'green',
    padding : '5px 10px',
    borderRadius : '0 0 80% 80%', 
    marginTop : '-10px',
  },
  eachH4 : {
    fontSize : '12px',
    fontWeight : '50',
    color : 'gray'
  },
  eachBox : {
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center'
  },
  cardsEnqApp : {
    backgroundColor : '#65ac4c',
    padding : '7px',
    width : '48%',
    textAlign : 'center',
    color : 'white',
    fontWeight : '100',
    fontSize : '14px',
    display : 'flex',
    justifyContent : 'space-around ',
    alignItems : 'center',
    flexWrap : 'nowrap',
    border : '1px solid white',
    borderRadius : '5px',
    borderRadius : '5px'
  },
  enqAppIcon : {
    fontSize : '30px',
    color : 'white',
    border : '2px solid white',
    borderRadius : '50%',
    padding : '5px'
  },
 followUs : {
  color : 'gray',
  fontSize : '14px'
 }
}



const mobileStyles = {
  logos : {
    display : 'flex',
    justifyContent : 'space-between',
    
  },
  navCards : {
    backgroundColor : 'lightgray',
    width : '14vw',
    height : 'fit-content',
    display : 'flex',
    flexDirection : 'column',
    alignItems : 'center',
    border : '2px solid white',
    borderRadius : '8px',
    fontSize : '12px',
    textWrap : 'nowrap'
  },
  navIcons : {
    fontSize : '8vw',
    color : 'gray'
  },
  userCard : {
    display:'flex',
    flexDirection:'column',
    padding:'3vw',
    background:'linear-gradient(145deg, #1F277B 20%, white 20%,lightgray 100%)',
    color : 'black',
    boxShadow : '0 0 20px rgba(173, 216, 230, 0.5);',
    height : 'auto'
  },
  logo : {
    width:'5vw',
    height:'5vw',
    marginTop:'15px',
    backgroundColor : 'white',
    borderRadius : '5px',
    padding : '2px 10px'
  },
  navH3 : {
    fontSize : '10px'
  },
  cardsEnqApp : {
    backgroundColor : '#65ac4c',
    padding : '7px',
    width : '48%',
    textAlign : 'center',
    color : 'white',
    fontWeight : '100',
    fontSize : '12px',
    display : 'flex',
    justifyContent : 'space-around ',
    alignItems : 'center',
    flexWrap : 'nowrap',
    border : '1px solid white'
  },
  sendEnquiry : {
    backgroundColor : '#65ac4c',
    padding : '15px',
    width : '48%',
    textAlign : 'center',
    color : 'white',
    fontWeight : '100',
    fontSize : '12px',
    display : 'flex',
    justifyContent : 'space-around ',
    alignItems : 'center',
    flexWrap : 'nowrap',
    border : '1px solid white',
    borderRadius : '5px'
  },
  enqAppIcon : {
    fontSize : '26px',
    color : 'white',
    border : '2px solid white',
    borderRadius : '50%',
    padding : '5px'
  },
  pagination : {
    position : 'fixed',
    bottom : '50px',
    display : 'flex',
    justifyContent : 'space-evenly',
    gap : '2px',
    width : '310px'
  },
  socialMediaCont: {
    position : 'fixed',
    bottom : '170px',
    display:'flex',
    marginTop:'30px',
    justifyContent:'space-around',
    gap : '2px',
    zIndex : '50',
    marginLeft : '25vw'
  },
  followUs : {
    color : 'gray',
    fontSize : '14px',
    marginBottom : '10vh'
   },
   header : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent : 'center',
    alignItems : 'center',
    gap : '50px',
    marginTop : '-10px'
  }
  
  
}

function handleScroll(event) {
  event.preventDefault();
  const targetId = event.currentTarget.getAttribute("href");
  const targetElement = document.querySelector(targetId);

  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth" })
}
}


const Theme3 = (props) => {
  
  const {card, callMe, whatsappMe, mailMe, initApptBooking, initEnquiryBooking,
         openUrl, cartData, updateCart, showCartModal} = props
         const isDesktop = true

         const isSmallScreen = useMediaQuery('(max-width : 600px)')
        
         
         

  return (<>
    {
      card ? <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', background:'#f2fcff', 
                        padding: isDesktop ? '20px' : ''}}> 
      <Box p={2} sx={{background:'linear-gradient(180deg, black, transparent)', maxWidth:'500px'  }}>
        
        <Paper style={isSmallScreen ? mobileStyles.userCard :  styles.userCard}>

          <div style={isSmallScreen ? mobileStyles.header : styles.header}>
            {card?.profilePicUrl ? (isSmallScreen ? <ImageLoader props={{imgUrl:card.profilePicUrl, 
            styles:mobileStyles.logo}}/> :<ImageLoader props={{imgUrl:card.profilePicUrl, styles:styles.logo}}/>)
             : <AccountCircleIcon style={styles.defIcon}/>}
            <h3 style={{color : 'white', marginTop : '30px'}}>Full Stack Developer</h3>
          </div>

          <Card variant="outlined" sx={styles.card}>
            <Box sx={isSmallScreen ? mobileStyles.logos : styles.logos}>
              <div><ImageLoader props={{imgUrl:card.profilePicUrl, styles:styles.profile}}/></div>
              <div>{isSmallScreen ? <ImageLoader props={{imgUrl:card.profilePicUrl, styles:mobileStyles.logo}}/>
               :<ImageLoader props={{imgUrl:card.profilePicUrl, styles:styles.logo}}/>}</div>
            </Box>
            <Box>
              <Box style={styles.userNameCont}>
              { card.fullName } 
              </Box>
              <Box style={styles.professionCont}>
                <p>{ card.profession },</p> 
                <p>{ card.address }</p>
              </Box>
            </Box>
            <FaShareAlt style={styles.shareIcon}/>
          </Card>

          <Card variant="outlined" style={styles.socialMediaCard}>
          <Box style={styles.contactCont}>
            <Box sx={styles.eachBox}>
              {
                card.mobileNo ? 
                <FiPhoneCall style={styles.circleMobile} onClick={() => callMe(card.mobileNo)}/>
                : null
              }
              <h4 style={styles.eachH4}>CALL</h4>
            </Box>

            <Box sx={styles.eachBox}>
              {
                card.whatsappNo ? 
                <WhatsAppIcon style={styles.circleWhatsup} onClick={() => whatsappMe(card.whatsappNo)}/> : null
              }
              <h4 style={styles.eachH4}>WHATSAPP</h4>
            </Box>

            <Box sx={styles.eachBox}>
              {
                card.email ? 
                <EmailIcon style={styles.circleEmail} onClick={() => mailMe(card.email)}/> : null
              }
              <h4 style={styles.eachH4}>EMAIL</h4>
            </Box>
            <Box sx={styles.eachBox}>
              {
                <LanguageIcon style={styles.circleWeb} onClick={() => openUrl(card.mediaLinks.websiteLink)} />
              }
              <h4 style={styles.eachH4}>WEBSITE</h4>
            </Box>

          </Box>
          </Card>

          <Box style={styles.enquiryCont}>
            <Card onClick={initApptBooking} sx={isSmallScreen ? mobileStyles.cardsEnqApp : styles.cardsEnqApp}>
              <h3>Book Appointment</h3>
              <MdOutlineBookmarkAdded style={isSmallScreen ? mobileStyles.enqAppIcon : styles.enqAppIcon}/>
            </Card>
            <Card onClick={initEnquiryBooking} sx={isSmallScreen ? mobileStyles.sendEnquiry : styles.cardsEnqApp}>
              <h3>Send Enquiry</h3>
              <LuSendHorizonal style={isSmallScreen ? mobileStyles.enqAppIcon : styles.enqAppIcon}/>
            </Card>
            
          </Box>

          <Grid container spacing={2} gap={isSmallScreen ? 2 : 5}>
            <Grid item  md={3}>
              <a href="#aboutMe" style={styles.a} onClick={handleScroll}>
              <Card sx={isSmallScreen ? mobileStyles.navCards : styles.navCards}>
                <FaUserTie style={isSmallScreen ? mobileStyles.navIcons : styles.navIcons }/>
                <h3 style={isSmallScreen ? mobileStyles.navH3 : styles.navH3}>ABOUT ME</h3>
              </Card>
              </a>
            </Grid>
            <Grid item md={3}>
              <a href="#companies" style={styles.a} onClick={handleScroll}>
              <Card sx={isSmallScreen ? mobileStyles.navCards : styles.navCards}>
                <LiaIndustrySolid style={isSmallScreen ? mobileStyles.navIcons : styles.navIcons }/>
                <h3 style={isSmallScreen ? mobileStyles.navH3 : styles.navH3}>COMPANIES</h3>
              </Card>
              </a>
            </Grid>
            <Grid item md={3}>
              <a href="#ecommerce" style={styles.a} onClick={handleScroll}>
              <Card sx={isSmallScreen ? mobileStyles.navCards : styles.navCards}>
                <StoreIcon style={isSmallScreen ? mobileStyles.navIcons : styles.navIcons }/>
                <h3 style={isSmallScreen ? mobileStyles.navH3 : styles.navH3}>ECOMMERCE</h3>
              </Card>
              </a>
            </Grid>
            <Grid item  md={3}>
              <a href="#gallery" style={styles.a} onClick={handleScroll}>
              <Card sx={isSmallScreen ? mobileStyles.navCards : styles.navCards}>
                <CollectionsIcon style={isSmallScreen ? mobileStyles.navIcons : styles.navIcons }/>
                <h3 style={isSmallScreen ? mobileStyles.navH3 : styles.navH3}>GALLERY</h3>
              </Card>
              </a>
            </Grid>
            <Grid item md={3}>
              <a href="#payment" style={styles.a} onClick={handleScroll}>
              <Card sx={isSmallScreen ? mobileStyles.navCards : styles.navCards}>
                <PaymentsIcon style={isSmallScreen ? mobileStyles.navIcons : styles.navIcons }/>
                <h3 style={isSmallScreen ? mobileStyles.navH3 : styles.navH3}>PAYMENT</h3>
              </Card>
              </a>
            </Grid>
            <Grid item md={3}>
              <a href="#payment" style={styles.a} onClick={handleScroll}>
              <Card sx={isSmallScreen ? mobileStyles.navCards : styles.navCards}>
                <PaymentsIcon style={isSmallScreen ? mobileStyles.navIcons : styles.navIcons }/>
                <h3 style={isSmallScreen ? mobileStyles.navH3 : styles.navH3}>SOCIAL MEDIA</h3>
              </Card>
              </a>
            </Grid>
            
          </Grid>
          
          <Box sx={isSmallScreen ? styles.aboutMe : ''} className="aboutMe">
            <h3 id="aboutMe">ABOUT ME</h3>
            <p>{card.aboutMe}</p>
            <p>{card.profession}</p>
          </Box>
          

          <Box>
            {
              card.companies ? 
              <>
                <h3 id="companies">Companies</h3>
                <Box style={styles.companiesCont}>
                {
                  card.companies.map((company, index) => {
                    return <Box key={index} style={styles.companyCont}>
                      {
                        company.logo ?
                        <ImageLoader props={{imgUrl: company.logo ? company.logo : null, styles : styles.companyImg}} />
                        : null
                      }
                      <div style={styles.companyNameCont}>{company.name}</div>
                      <div style={styles.companyDescCont}>{company.description}</div>
                    </Box>
                  })
                }
                </Box>
              </> : null
            }
          </Box>

          <Box>
            {
              card.products ? 
              <>
                <h3>Products & Services</h3>
                <Box style={styles.productsCont}>
                {
                  card.products.map((product, index) => {
                    return <Box key={index} style={styles.productCont}>
                      <div style={styles.productNameCont}> {product.name} </div>  
                      <div style={styles.productDescCont}> {product.description} </div> 
                      {
                        product.imgUrls ?
                        <ImageLoader props={{imgUrl: product?.imgUrls[0], styles : styles.prodImg}} /> : null
                      }
                    </Box>
                  })
                }
                </Box>
              </> : null
            }
          </Box>

          <Box>
            {
              card?.ecommProducts?.length ? 
              <>
                <h3 style={styles.h3Tag} id="ecommerce">Ecommerce</h3>
                <Grid container spacing={3} justifyContent="center" p={2}>
                {
                  card.ecommProducts.map((product, index) => {
                    return <Grid item xs key={index} style={styles.ecommCont}>
                      <ImageLoader props={{imgUrl: product.imgUrls[0], styles : styles.ecommProdImg}} />
                      <Box sx={{fontSize:'17px', fontWeight:'bold'}}>{product.name}</Box>
                      <Box sx={{fontSize:'15px', mt:1, mb:1}}>{product.description}</Box>
                      <Box sx={{fontSize:'17px', mb:1}}>₹ {product.price}</Box>
                      
                      {
                        cartData[product.id] ? 
                        <>
                        <Box sx={{ 
                              display:'flex', 
                              borderRadius:'5px', 
                              justifyContent:'space-around', height:'30px',
                              background:'white', border:'1px solid #c3c3c3'}}>
                          <Box onClick={() => updateCart(product, false)}
                            sx={{padding:'5px 10px 5px 10px', fontSize:'15px', cursor:'pointer'}}>
                            -
                          </Box>
                          <Box sx={{padding:'5px 10px', 
                                    borderRight:'1px solid #bababa', 
                                    borderLeft:'1px solid #bababa', fontSize:'15px',
                                    background:'#058cdb !important', color:'white'}}>
                            {cartData[product.id].count}
                          </Box>
                          <Box  onClick={() => updateCart(product, true)}
                            sx={{padding:'5px 10px 5px 10px', fontSize:'15px', cursor:'pointer'}}>
                            +
                          </Box>
                        </Box>
                        </>  : 
                        <Button variant="contained" 
                          onClick={() => updateCart(product, true)} sx={{textWrap : 'nowrap'}}>Add To Cart</Button>
                      }

                    </Grid>
                  })
                }
                </Grid>
              </> : null
            }
          </Box>

          <Box>
            {
              card.gallery ? 
              <>
                <h3 style={styles.h3Tag} id="gallery">Gallery</h3>
                <Grid container spacing={3} justifyContent="center">
                  {
                    card.gallery.imgUrls.map((imgUrl, index) => {
                      return<Grid item xs key={index}>
                        <ImageLoader props={{imgUrl: imgUrl, styles : styles.galleryImg}} />
                      </Grid>
                    })
                  }
                </Grid>
              </> : null
            }
          </Box>

          <Box>
            {
              card.payments ? 
              <>
                <h3 style={styles.h3Tag} id="payment">Payment Details</h3>
                {
                  card.payments.accounts.map((account, index) => {
                    return <Box key={index} sx={styles.paymentDetails}>
                      {
                        <>
                          <Box>
                            <Box style={styles.paymentLabel}>Account Name</Box>
                            <Box> {account.accountName} </Box>
                          </Box>
                          <Box>
                            <Box style={styles.paymentLabel}>Account Number</Box>
                            <Box> {account.accountNumber} </Box>
                          </Box>
                          <Box>
                            <Box style={styles.paymentLabel}>IFSC Code</Box>
                            <Box> {account.ifscCode} </Box>
                          </Box>
                        </>
                      }
                    </Box>
                  })
                }

                <Box sx={{borderTop:'1px solid #eaeaea', marginTop:'10px', color : 'black'}}>
                  <Box style={styles.paymentLabel}>UPI ID</Box>
                  <Box> {card.payments.upi} </Box>
                </Box>

                {
                  card.payments && card.payments.qrImgUrls ?
                  <Box sx={{borderTop:'1px solid #eaeaea', marginTop:'10px'}}>
                    <Box style={styles.paymentLabel}>Payment QR</Box>
                    <ImageLoader props={{imgUrl: card.payments.qrImgUrls[0], styles : styles.prodImg}} />
                  </Box> : null
                }
                
              </> : null
            }
          </Box>
          <Box>
            <p style={isSmallScreen ? mobileStyles.followUs : styles.followUs}>FOLLOW US ON:</p>
            <Box style={isSmallScreen ? mobileStyles.socialMediaCont : styles.socialMediaCont}>
              {
                card?.mediaLinks?.facebookLink ? 
                <FacebookIcon style={styles.squareIcon} onClick={() => openUrl(card.mediaLinks.facebookLink)} /> : null
              }
              {
                card?.mediaLinks?.twitterLink ? 
                <TwitterIcon style={styles.squareIcon} onClick={() => openUrl(card.mediaLinks.twitterLink)} /> : null
              }
              {
                card?.mediaLinks?.linkedinLink ? 
                <LinkedInIcon style={styles.squareIcon} onClick={() => openUrl(card.mediaLinks.linkedinLink)} /> : null
              }
              {
                card?.mediaLinks?.instagramLink ? 
                <InstagramIcon style={styles.squareIcon} onClick={() => openUrl(card.mediaLinks.instagramLink)} /> : null
              }
              {
                card?.mediaLinks?.websiteLink ? 
                <LanguageIcon style={styles.squareIcon} onClick={() => openUrl(card.mediaLinks.websiteLink)} /> : null
              }
            </Box>
          </Box>
        </Paper>
        <Paper sx={isSmallScreen ? mobileStyles.pagination: styles.pagination}>
            <a href="#companies" style={styles.a} onClick={handleScroll}>
              <Box  sx={styles.eachBoxPagination}>
                <FaHandshake style={styles.eachPagiIcon}/>
                <h5 >COMPANY</h5>
              </Box>
            </a>
            <a href="https://brandorigin.in/" style={styles.a}>
            <Box sx={styles.eachBoxPagination} >
              <FaGlobe style={styles.webPagination}/>
              <h5>WEBSITE</h5>
            </Box>
            </a>
            <a href="#" style={styles.a}>
              <Box sx={styles.eachBoxPagination}>
                <FaLocationDot style={styles.eachPagiIcon}/>
                <h5>LOCATION</h5>
              </Box>
            </a>
        </Paper>
        {
          cartData.totalCount ? 
          <Fab color="primary" aria-label="add"
            sx={{position:'sticky', bottom:'20px', left:'100vw'}}
              onClick={() => showCartModal()}>
              <Badge badgeContent={cartData.totalCount} color="secondary">
                <ShoppingCartIcon />
              </Badge>
          </Fab> : null
        }
        
      </Box>
      
     </Box>: 
      <>
        Card Not Found
      </>
    }
  </>)
}

export default CardBase(Theme3)